a,
.green {
  text-decoration: none;
  color: hsla(160, 100%, 37%, 1);
  transition: 0.4s;
}

#app, .app {
  display: flex;
  place-items: center;
  margin: 0 auto;
  padding: 4rem 1rem;
  font-weight: normal;

}
header {
  min-width: 240px;
  line-height: 1.5;
  margin: 2rem;
  width: auto;
}
.logo {
  display: block;
  margin: 0 auto 2rem;
}

@media (max-width: 768px) {
  #app {
    display: grid;
    place-items: center;
  }
}

.item:hover i, .item:hover .details {
  background-color:  var(--color-item-hover);
}
/* .item:hover .details{
  background-color: rgb(230,230,230);
} */


@media (hover: hover) {
  a:hover {
    background-color: hsla(160, 100%, 37%, 0.2);
  }
  
}

/* @media (prefers-reduced-motion: no-preference) {
  .logo {
    animation: logo-spin infinite 30s linear;
  }
} */

@keyframes logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
