/* StartItem ----- */
.item {
    margin-top: 2rem;
    display: flex;
    position: relative;
  }
  img{
    flex: 1;
  }
  .details {
    /* flex: 1; */
    padding: 0.4rem;
    border-radius: 10px;
  }
  
  i {
    display: flex;
    place-items: center;
    place-content: center;
    font-size: 2rem;
    color: var(--color-text);
    height: 52px;
  }
  
  h3 {
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 0.4rem;
    color: var(--color-heading);
  }
  
  @media (max-width: 1800px) {
    .item {
      margin-top: 0;
      padding: 0.4rem 0 0.4rem calc(var(--section-gap) / 4);
    }
  
    i {
      top: calc(50% - 25px);
      left: -26px;
      position: absolute;
      border: 1px solid var(--color-border);
      background: var(--color-background);
      border-radius: 8px;
      width: 50px;
      height: 50px;
    }
  
    .item:before {
      content: ' ';
      border-left: 1px solid var(--color-border);
      position: absolute;
      left: 0;
      bottom: calc(50% + 25px);
      height: calc(50% - 25px);
    }
  
    .item:after {
      content: ' ';
      border-left: 1px solid var(--color-border);
      position: absolute;
      left: 0;
      top: calc(50% + 25px);
      height: calc(50% - 25px);
    }
  
    .item:first-of-type:before {
      display: none;
    }
  
    .item:last-of-type:after {
      display: none;
    }
  }
  /* ------ StartItem */