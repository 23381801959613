nav, .navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 0.5rem;
}
.dropdown-content {
    display: none;
    position: absolute;
    background-color: #e6e6e6;
    margin: 0px;
    padding: 3px;
    border-radius: 5px;
    right:0;
    z-index: 1;
}
.dropdown {
    position: relative;
    display: inline-block;
}
.dropdown:hover .dropdown-content, .dropdown-content:hover {
    display: block;
}
.circle {
    width: 30px; 
    height: 30px; 
    border-radius: 50%; 
    overflow: hidden; 
    margin-right: 5px;
}
a {
border-radius: 5px;
margin: 2px;
}
.profile-dropdown .dropdown-menu[data-bs-popper] {
    left: unset !important;
    right: 0 !important;
    /* margin: 0 5px 0 5px; */
}

.profile-dropdown .dropdown-item, .company-dropdown .dropdown-item {
  margin:0;
  width:auto;
}

.dropdown-item.active{
    background-color: lightslategray;
}

input[type="checkbox"] {
        background-color: lightgray !important;
        border:0px;
        color: white;
    }